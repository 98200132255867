@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        charityfair
Version:        1.0
Last change:    07.09.2016
Primary use:    Nonprofit, Crowdfunding & Charity HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,500,700,600,800);

 
// Table of contents
@import "less-charityfair/table-of-content.less";

 
// Initialize Variables
@import "less-charityfair/variables.less";
@import "less-charityfair/mixins.less";
// Typography
@import "less-charityfair/typography.less";

// Common Styles
@import "less-charityfair/common.less";
@import "less-charityfair/extra.less";
@import "less-charityfair/overlay.less";

// Header
@import "less-charityfair/header.less";

// Nav
@import "less-charityfair/nav.less";

// Content Blocks
@import "less-charityfair/topbar.less";
@import "less-charityfair/inner-header-title.less";
@import "less-charityfair/vertical-nav.less";
@import "less-charityfair/menu-full-page.less";
@import "less-charityfair/boxed-layout.less";
@import "less-charityfair/form.less";
@import "less-charityfair/side-push-panel.less";
@import "less-charityfair/box-hover-effect.less";
@import "less-charityfair/gallery-isotope.less";
@import "less-charityfair/sliders.less";
@import "less-charityfair/home.less";
@import "less-charityfair/about.less";
@import "less-charityfair/causes.less";
@import "less-charityfair/volunteer.less";
@import "less-charityfair/contact.less";
@import "less-charityfair/event.less";
@import "less-charityfair/practice-area.less";
@import "less-charityfair/services.less";
@import "less-charityfair/job.less";
@import "less-charityfair/shop.less";
@import "less-charityfair/blog.less";

// Shortcodes
@import "less-charityfair/shortcodes.less";


// Widgets
@import "less-charityfair/widgets.less";


// Footer
@import "less-charityfair/footer.less";